import React from 'react';
import { Router, useRouter } from 'next/router';
import api from 'utils/api';
import { useStores } from 'hooks/useStores';
import { observer } from 'mobx-react';
import { toast } from 'react-nextjs-toast';
import { withTranslation } from 'i18n';
import agent from 'utils/agent';
import Head from 'next/head';
import { toJS } from 'mobx';
import { setThemeColor } from 'utils/helper';

function FacebookPixel() {
  const { cartStore } = useStores();
  React.useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT && process.env.NEXT_PUBLIC_ENVIRONMENT == 'production' && cartStore.currentRestaurant && cartStore.currentRestaurant.fb_pixel) {
      import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(cartStore.currentRestaurant.fb_pixel);
        ReactPixel.pageView();
        Router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
    }
  });
  return null;
}

const AppLayout = observer(({ children, t, i18n, data }) => {
  const router = useRouter();
  const { cartStore, authStore } = useStores();
  const { restaurant, store, channel, orderType } = router.query;

  React.useEffect(() => {
    authStore.setLang(i18n.language);
  }, [i18n]);

  React.useEffect(() => {
    
    //setting theme color 

    if (cartStore.currentRestaurant && cartStore.currentRestaurant.settings && cartStore.currentRestaurant.settings.theme_style && cartStore.currentRestaurant.settings.theme_style.theme_color) {
      setThemeColor(cartStore.currentRestaurant.settings.theme_style);
    }
  }, [cartStore.currentRestaurant]);

  React.useEffect(() => {
    // console.log('RESTTT::', toJS(cartStore.currentRestaurant));
    if (orderType && orderType === 'delivery' && cartStore.currentArea && cartStore.currentArea.id && cartStore.currentOutlet && cartStore.currentOutlet.id && cartStore.deliveryDetails && cartStore.deliveryDetails.id != cartStore.currentArea.id) {
      // console.log('LOADING AREA');
      // cartStore.loadAreaDetails();
      // cartStore.loadOutlet(cartStore.currentArea.id);
    }
  }, [cartStore.currentArea, cartStore.currentOutlet]);

  React.useEffect(() => {

    // console.log("CHANNEL:", channel);
    // let ch = 'web';

    // switch (channel) {
    //   case 'f':
    //     ch = 'facebook';
    //     break;
    //   case 'facebook':
    //     ch = 'facebook';
    //     break;
    //   case 'i':
    //     ch = 'instagram';
    //     break;
    //   case 'instagram':
    //     ch = 'instagram';
    //     break;
    //   case 'w':
    //     ch = 'whatsapp';
    //     break;
    //   case 'whatsapp':
    //     ch = 'whatsapp';
    //     break;
    //   case 'sms':
    //     ch = 'sms';
    //   default:
    //     ch = 'web';
    // }

    if (document.referrer.indexOf('facebook.com') >= 0) {
      cartStore.setChannel('facebook');
    }
    if (document.referrer.indexOf('instagram.com') >= 0) {
      cartStore.setChannel('instagram');
    }
    // else {
    //   cartStore.setChannel(ch);
    // }
    // console.log('REFREERER:', document.referrer);
  }, []);

  // React.useEffect(() => {
  //   // if (!cartStore.cartTotalAmount.total) cartStore.resetCart();
  // }, [cartStore.cart]);

  React.useEffect(() => {
    // console.log('APP LAYOUT');
    if (
      // router.pathname !== '/[restaurant]/menu' &&
      restaurant &&
      (!cartStore.currentRestaurant ||
        !cartStore.currentRestaurant.url ||
        cartStore.currentRestaurant.url !== restaurant)
    ) {
      // console.log('Loading Restaurant');
      agent.Data.all(`/restaurant/${restaurant}`)
        .then((res) => {
          console.log('Restaurant Loaded', res);
          cartStore.setRestaurant(res);
          if (cartStore.currentRestaurant.id) {
            // console.log('Restaurant Loaded 0');
            // if (cartStore.orderType === 'delivery') {
            //   // console.log('Delivery Type');
            //   if (cartStore.currentArea && cartStore.currentArea.id) {
            //     console.log('LOADING OUTLET FROM APPLAYOUT');
            //     cartStore.loadOutlet(cartStore.currentArea.id);
            //   }
            // } else {
            //   //making default orderType delivery
            //   if (!cartStore.orderType) {
            //     const d = cartStore.currentRestaurant.has_delivery
            //       ? 'delivery'
            //       : 'pickup';
            //     cartStore.setOrderType(d);
            //   }

            //   // console.log('Pickup Type');
            //   const currentStore =
            //     store ||
            //     (cartStore.currentOutlet && cartStore.currentOutlet.url);
            //   // console.log('Current Store', currentStore);
            //   if (currentStore) {
            //     console.log('Loading Outlet from App Layout')
            //     cartStore.loadOutlet(currentStore, 'outlet');
            //   }
            // }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 404) {
              // console.log('Restaurant not found', err.response);
              toast.notify(`Restaurant not found.`, {
                title: 'Error.',
                type: 'error'
              });
            } else {
              toast.notify(`Something went wrong!!!`, {
                title: 'Error.',
                type: 'error'
              });
              // console.log('Something went wrong', err.response);
            }
          }
          router.push('/404');
        });
    }
  }, []);

  if (!cartStore.currentRestaurant || !cartStore.currentRestaurant.url)
    return null;

  if (!restaurant) return <div className="h-full">Page not found</div>;

  return (
    <>
      {/* Global Site Code Pixel - Facebook Pixel */}
      {process.env.NEXT_PUBLIC_ENVIRONMENT && process.env.NEXT_PUBLIC_ENVIRONMENT == 'production' && cartStore.currentRestaurant && cartStore.currentRestaurant.fb_pixel && (
        <Head>
          <script
            dangerouslySetInnerHTML={{
              __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${cartStore.currentRestaurant.fb_pixel});
              `
            }}
          />
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              src={`https://www.facebook.com/tr?id=${cartStore.currentRestaurant.fb_pixel}&ev=PageView&noscript=1`}
            />
          </noscript>
        </Head>
      )}
      {process.env.NEXT_PUBLIC_ENVIRONMENT && process.env.NEXT_PUBLIC_ENVIRONMENT == 'production' ? <FacebookPixel /> : null }
      <div className={`h-full flex flex-col ${i18n.language}`}>{children}</div>
    </>
  );
});

export default withTranslation('common')(AppLayout);
