import { toast } from 'react-nextjs-toast';

export const discountText = (discount) => {
  let value = discount.discount_value;

  if (discount.discount_type.id == 1)
    value = `${Number(discount.discount_value)}%`;
  if (discount.discount_type.id == 2) value = `AED ${discount.discount_value}`;

  let discount_for = 'all orders';

  if (discount.number_of_uses_customer > 0)
    discount_for = `your next ${discount.number_of_uses_customer} orders`;
  if (discount.number_of_uses_customer == 1) discount_for = `your next order`;

  return `Get ${value} off ${discount_for}`;
};

export function cartQuantity(menu, cart) {
  let c = 0;
  cart.map((el) => {
    if (el.menu_id == menu.id) {
      c += el.quantity;
    }
  });

  return c;
}

export function log(message) {
  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT &&
    (process.env.NEXT_PUBLIC_ENVIRONMENT === 'local' ||
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging')
  ) {
    console.log(message);
  }
}

export function showErrorMessage(err, title = 'Error') {
  let message = '';
  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT &&
    (process.env.NEXT_PUBLIC_ENVIRONMENT === 'local' ||
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging')
  ) {
    console.log(err.response, err.response.body);
  }
  if (err.response && err.response.body) {
    if (err.response.body.errors) {
      for (var key in err.response.body.errors) {
        for (var error of err.response.body.errors[key]) {
          if (error.length > 3) {
            toast.notify(error, {
              title: title,
              type: 'error'
            });
          }
        }
      }
    } else if (err.response.body.message) {
      toast.notify(err.response.body.message, {
        title: title,
        type: 'error'
      });
    } else {
      message = 'Something went wrong';
    }
  } else {
    message = 'Something went wrong';
  }
  if (message.length > 3) {
    toast.notify(message, {
      title: title,
      type: 'error'
    });
  }
  // message = err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Something went wrong';

  // console.log(message);
  // return message;
}

function hexToHSL(H) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length == 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length == 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
}

export function setThemeColor(theme) {
  log('CHANGING THEME:', theme);
  const hsl = hexToHSL(theme.theme_color);
  document.documentElement.style.setProperty('--color-primary-h', `${hsl.h}`);
  document.documentElement.style.setProperty('--color-primary-s', `${hsl.s}%`);
  document.documentElement.style.setProperty('--color-primary-l', `${hsl.l}%`);
  if (theme.button_text_color) {
    document.documentElement.style.setProperty(
      '--color-button-text',
      `${theme.button_text_color}`
    );
  }
}

export function getThemeColor(currentRestaurant) {
  return currentRestaurant &&
    currentRestaurant.settings &&
    currentRestaurant.settings.theme_style &&
    currentRestaurant.settings.theme_style.theme_color
    ? `${currentRestaurant.settings.theme_style.theme_color}`
    : '#E50071';
}
