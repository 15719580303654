import styled, { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import tw from 'twin.macro';
// import 'react-phone-number-input/style.css'

const TIMEOUT = 400;

export const InjectRTL = styled.div`
  ${({ lang }) =>
    (lang === 'ar' || lang === 'he') &&
    `
    font-family: 'Almarai', sans-serif;
    `}
`;

export const RtlStyle = createGlobalStyle`  
/* 
:root {
    --color-primary-h: 150;
    --color-primary-s: 84%;
    --color-primary-l: 29.4%;
} */

  body{
    font-family: 'Almarai', sans-serif !important;
  }
  * {
    ${'' /* font-family: 'Almarai', sans-serif; */}
  }

  .checkmark {
    left: auto !important;
    right: 0 !important;
  }
`;

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
    ${'' /* font-family: 'Roboto', sans-serif; */}
  }
  body{
    margin: 0;
    display: flex;
    align-items: stretch;
    min-height: 100%;
    /* font-family: 'Roboto', sans-serif; */
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    background-color: #f5f5f5;
    width: 100%;
    max-width: 460px;
    font-size: 14px;
  }

  h1,h2,h3,h4,h5,h6  {
    ${'' /* font-family: ${themeGet('fontFamily.1', 'sans-serif')}; */}
    font-family: 'Nunito Sans',sans-serif;
    margin: 0;
    font-weight: 500;
  }
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  h4 {
    font-size: 1.0rem;
  }

  p,a,span,button,li,div  {
    ${'' /* font-family: ${themeGet('fontFamily.0', 'sans-serif')}; */}
    margin: 0;
  }
  ul{
    margin: 0;
    padding: 0;
  }
  li{
    list-style: none;
  }

  a{
    text-decoration: none;
  }

  input {
    ${tw`border-solid`}
  }
  button {
    ${tw`border-solid transition-all duration-500 cursor-pointer`}
  }

  #__next {
    width: 100%;
    min-width: 300px;
    max-width: 460px;
    position: relative;
    background-color: #fff;
  }

  .quick-view-overlay{
    background-color: rgba(0,0,0,.5)
  }

  /* .font-bold {
    font-weight: 700;
  }

  .font-semibold {
    font-weight: 600;
  } */

  .add-address-modal,
  .add-contact-modal{
    box-shadow: 0 10px 40px rgba(0,0,0,0.16);
    border-radius: 3px !important;
    .innerRndComponent{
      width: 100%;
      padding: 30px;
      height: auto;
      background-color: #f7f8f9;
      border: 0;
      box-sizing: border-box;
    }
  }

  .search-modal-mobile{
    transform: none!important;
    max-width: none!important;
    max-height: none!important;
    top: 0!important;
    left: 0!important;
    background: transparent!important;;
    border-radius: 0!important;
  }

  .reuseModalCloseBtn{
    right: 10px!important;
    background-color: #ffffff!important;
    color: #222222!important;
    border-radius: 15px!important;
    padding: 0 9px!important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.4);
  }



  .page-transition-enter {
    opacity: 0;
    height: 100%;
    transform: translate3d(0, 20px, 0);
  }
  .page-transition-enter-active {
    opacity: 1;
    height: 100%;
    transform: translate3d(0, 0, 0);
    transition: opacity ${TIMEOUT}ms, transform ${TIMEOUT}ms;
  }
  .page-transition-exit {
    height: 100%;
    opacity: 1;
  }
  .page-transition-enter-done {
    height: 100%;
    opacity: 1;
  }
  .page-transition-exit-active {
    height: 100%;
    opacity: 0;
    transition: opacity ${TIMEOUT}ms;
  }
  .loading-indicator-appear,
  .loading-indicator-enter {
    opacity: 0;
  }
  .loading-indicator-appear-active,
  .loading-indicator-enter-active {
    opacity: 1;
    transition: opacity ${TIMEOUT}ms;
  }

  .image-item{
    padding: 0 15px;
  }

  @media (max-width: 1199px) and (min-width: 991px) {
    .image-item{
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media (max-width: 768px) {
    .image-item{
      padding-left: 7.5px;
      padding-right: 7.5px;
    }
  }

  .rc-table-fixed-header .rc-table-scroll .rc-table-header{
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;

    th {
      padding: 8px 20px;
      }
  }

  .drawer-content-wrapper{
    *:focus {
      outline: none;
    }
  }

  .rc-table-content{
    border: 0;
  }

  .relative {
    position: relative;
  }

  .btn {
    ${tw`text-buttontext p-3 transition-all duration-500 bg-primary rounded cursor-pointer w-full border-0 flex justify-around hover:bg-primary-dark`}

    &.btn-sm {
      ${tw`p-2`}
    }
  }

  .close-btn {
    ${tw`absolute top-0 right-0`}
  }
  
  .p {
    padding: 1rem;
  }
  .pl {
    padding-left: 1rem;
  }
  .pr {
    padding-right: 1rem;
  }
  .pt {
    padding-top: 1rem;
  }
  .pb {
    padding-bottom: 1rem;
  }
  .py {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .px {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .m {
    margin: 1rem;
  }
  .ml {
    margin-left: 1rem;
  }
  .mr {
    margin-right: 1rem;
  }
  .mt {
    margin-top: 1rem;
  }
  .mb {
    margin-bottom: 1rem;
  }
  .my {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .mx {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .toast-message {
    border-radius: 4px !important;
    font-family: inherit !important;
  }

  .w-full {
    max-width: 460px;
  }
.slick-arrow {
  z-index: 2;
  &.slick-prev {
    left: 6px !important;
  }
  [dir='rtl'] & {
    &.slick-prev {
      right: 6px !important;
      left: auto !important;
    }
    &.slick-next {
      left: 6px !important;
      right: auto !important;
    }
  }
  &.slick-next {
    right: 6px !important;
  }
}
.slick-prev:before, .slick-next:before {
  color: gray;
  opacity: .9;
  font-size: 24px;
  line-height: 22px;
}

*:focus {
    outline: 0 !important;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #dfe2e7;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

#toast-container {
  z-index: 9999;
  bottom: 80px !important;
}

.truncate-line-1, .truncate-line-2, .truncate-line-3 {
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-line-1 {
    -webkit-line-clamp: 1;
}
.truncate-line-2 {
    -webkit-line-clamp: 2;
}
.truncate-line-3 {
    -webkit-line-clamp: 3;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Content {
  height: 100vh;
  top: 110vh !important;
  left: 0;
  position: absolute;
  width: 100%;
  /* -webkit-transform: scale(0.5); */
  /* transform: scale(0.5); */
}

.ReactModal__Content--after-open {
  /* -webkit-transform: scale(1);
  transform: scale(1); */
  top: 0 !important;
  transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close {
  /* -webkit-transform: scale(0.5);
  transform: scale(0.5); */
  top: 110vh !important;
  transition: all 150ms ease-in;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.page-transition-enter {
  opacity: 0;
}
.page-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.page-transition-exit {
  opacity: 1;
}
.page-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/
.StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }


  /**
  Google maps autocomplete
  */

  .pac-container {
  /* position: fixed !important;
  top: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 156px !important;
  border-top: none !important; */

  @media screen and (min-width: 1024) {
    top: calc(100% - 156px) !important;
    bottom: 0 !important;
    margin-top: 0 !important;
  }

  .pac-item {
    max-width: 1216px !important;
    margin-right: auto;
    margin-left: auto;
    padding-left: 16px;
    cursor: pointer;

    @media screen and (min-width: 1024px) and (max-width: 1366px) {
      max-width: 1088px !important;
    }

    @media screen and (min-width: 1024) {
      padding: 0;
    }

    &:nth-child(4),
    &:nth-child(5) {
      display: none;
    }

    & > span {
      /* color: color(neutral, 08); */
      /* font-weight: fonts(semi-bold); */
      font-size: .85rem;
      /* font-family: map-get($fonts, family); */
      line-height: 1.4rem;

      .pac-matched {
        /* color: color(neutral, 06); */
        /* font-weight: fonts(medium); */
      }
    }

    .pac-icon {
      display: none;
    }
  }

  &::after {
    height: 0 !important;
    background-image: none !important;
  }
}
`;
