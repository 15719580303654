import axios from "axios";
import { cartStore } from "stores/cart.store";

const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/api`,
  responseType: "json",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Accept: "application/json",
    // "restaurant-id": "1",
  },
});

// api.defaults.headers.common["Accept"] = "application/json";
// api.defaults.headers.common["Content-Type"] = "application/json";

// if (cartStore.currentRestaurant.id)
// api.defaults.headers.common["restaurant-id"] = cartStore.currentRestaurant.id;

// if (cartStore.currentOutlet.id)
// api.defaults.headers.common["outlet-id"] = cartStore.currentOutlet.id;

export default api;
